import React, { FunctionComponent, Component, useEffect, useState } from 'react';
import DashLayout from '../functionalComponents/DashboardLayout/DashboardLayout';
import DashRouters from '../../configs/routers/dashboard-routers.json';
import { Row, Col, Alert, Card, Button, Form, FormControl, Spinner, InputGroup  } from 'react-bootstrap';
import ObexRequestsHandler from '../../handlers/ObexRequestsHandler';
import ButtonSubmit from '../basicComponents/buttonSubmit';
import { useHistory } from 'react-router-dom';
import ObexProjectsRequestHandler from '../../handlers/ObexProjectsRequestsHandler';
import SessionService from '../../services/SessionsService';
import { faAddressCard, faBan, faBandAid, faEdit, faPlus, faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModifyCommunicationModal from './ModifyCommunication';
import AddLocationModal from './AddLoactionModal';
import { parseToNumberFormat } from '../../services/AmountService';
import iconAgregar from '../../assets/img/agregar-dirección2x.png';
import ClientAccount from './clientAccount';

type ClientsProps = {}



const Locations:FunctionComponent<ClientsProps> = (props) => {

  const history = useHistory();

  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clients, setClients] = useState(Array());
  const [stillLoaded, SetStillLoaded] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    getLocations();
    setShow(false);
  }

 const [idLoca, setidLoca] = useState(0);
 const [nombreLoca, setnombreLoca] = useState('');
 const [aliasLoca, setaliasLoca] = useState('');
 const [descripcionLoca, setdescripcionLoca] = useState('');
 const [imagenLoca, setimagenLoca] = useState('');
 const [aforopieLoca, setaforopieLoca] = useState(0);
 const [aforosentadosLoca, setaforosentadosLoca] = useState(0);
 const [nombrecompartidoLocaLoca, setcompartidoLoca] = useState(false);

  const handleOpen = () => {
   
    setnombreLoca('');
    setaliasLoca('');
    setdescripcionLoca('');
    setimagenLoca('');
    setaforopieLoca(0);
    setaforosentadosLoca(0);
    setcompartidoLoca(false);
    setidLoca(-1);

    setShow(true);
  }

  const getLocations = async () => {
    setLoading(true);
    SetStillLoaded(true);
    try {
      const {status, localizaciones, message } = await ObexProjectsRequestHandler.get(`/ws/events_agenda.py/get_localizaciones`);

      if (!status) throw { message };
      const clientsList = localizaciones.sort((a, b) => a.id > b.id ? 1 : -1);

      setClients(clientsList);
    } catch (error) {
      setError(error.message || error)
      setLoading(false);
      SetStillLoaded(false);
    };
    SetStillLoaded(false);
  };


  const handleEditLocation = async (event, client) => {

    console.log(event);
    console.log(client);
    event.preventDefault();
    event.stopPropagation();
    console.log(client.id);



    setnombreLoca(client.nombre);
    setaliasLoca(client.alias);
    setdescripcionLoca(client.descripcion);
    setimagenLoca(client.imagen);
    setaforopieLoca(client.aforo_de_pie);
    setaforosentadosLoca(client.aforo_sentado);
    setcompartidoLoca(client.Compartido);
    setidLoca(client.id);



    setShow(true)

  }

  const handleEraseLocation = async (event, client) => {

    SetStillLoaded(true);
    event.preventDefault();
    event.stopPropagation();
    console.log(client.id);
    const {status, message} = await ObexProjectsRequestHandler.get(`/ws/events_agenda.py/update_localizaciones?id=${client.id}&aforo_sentado=0&aforo_pie=0`);
    if (status){
      getLocations();
      }
      else {
      //setError(message);

      setTimeout(function() { 
      //setError('');

      }.bind(this), 2000)

      SetStillLoaded(false);
      }
    SetStillLoaded(false);
  }

  const handleClientDetail = (event, client) => {

    event.preventDefault();
    event.stopPropagation();
    SessionService.ClientConsult = client;
    history.push(`/employees${client.id}/data`);
  }

  useEffect(()  => {
    getLocations();
  },[]);


  return(
    <DashLayout sider={DashRouters} active="locations">

      {/*} nombreLoca, aliasLoca, descripcionLoca, imagenLoca, aforopieLoca, aforosentadosLoca, compartidoLoca */}
    <AddLocationModal
      show={show}
      handleClose={handleClose}
      nombreLoca={nombreLoca}
      aliasLoca={aliasLoca}
      descripcionLoca={descripcionLoca}
      imagenLoca={imagenLoca} 
      aforopieLoca={aforopieLoca}
      aforosentadosLoca={aforosentadosLoca}
      compartidoLoca={nombrecompartidoLocaLoca}
      idLoca={idLoca}
      />


      <Row>
      <Col md="9" className="mx-auto" style={{marginBottom:'40px'}}>
          
        <Row>
            <Col md={9}>
              <h1 className="mb-3">Listado de Zonas</h1>
              </Col>
              <Col  md={3}>
              <button className="btn-transparent" style={{ float: 'right', transform: 'translate(-15px,50%)'}}><FontAwesomeIcon icon={faPlusCircle} onClick={handleOpen}/> </button>
              </Col>
        </Row>

          

          <Row className="mb-3 border-bottom">
            <Col>Nombre</Col>
            <Col>Alias</Col>
            <Col>Descripcion</Col>
            <Col md="1">Aforo de Pie</Col>
            <Col md="1">Aforo Sentados</Col>
            <Col md="2">Acción</Col>
          </Row>

          {stillLoaded ?
        <Row className="h-100" >
          <Col md="1" className="mx-auto d-flex align-items-center" style={{ marginTop: '15em' }}>
            <Spinner animation="border"/>
          </Col>
        </Row>
        :
        <>

          {clients.map(client => { 
            if (client.aforo_de_pie+client.aforo_sentado>0){
            return (
              <Row className="mb-3">
                <Col className="">
                  { true ? <span><h6>{client.nombre}</h6></span>:<span>{client.nombre}</span>}
                </Col>
                <Col className="">
                { true ? <span><h6>{client.alias}</h6></span>:<span>{client.alias}</span>}
                </Col>
                <Col className="">
                { true ? <span><h6>{client.descripcion}</h6></span>:<span>{client.descripcion}</span>}
                </Col>
                <Col md="1">
                  {/*{ client.central ? <span><h6>{parseToNumberFormat(client.mindist)} m</h6></span>:<span>{parseToNumberFormat(client.mindist)} m</span>}*/}
                  { true ? <span><h6>{client.aforo_sentado}</h6></span>:<span>{client.aforo_sentado}</span>}
                </Col>
                <Col md="1">
                  {/*{ client.central ? <span><h6>{parseToNumberFormat(client.mindist)} m</h6></span>:<span>{parseToNumberFormat(client.mindist)} m</span>}*/}
                  { true ? <span><h6>{client.aforo_de_pie}</h6></span>:<span>{client.aforo_de_pie}</span>}
                </Col>


                <Col md="2">

                <Row>
                <Col md="2">
                { !client.central ?
                <Button onClick={(event) => handleEditLocation(event, client)} type="submit" className="btn-transparent">
                  <FontAwesomeIcon icon={faEdit}/> 
                </Button>
                :
                <></>
          }
                </Col>
                <Col md="2">
                  { true ?
                <Button onClick={(event) => handleEraseLocation(event, client)} type="submit" className="btn-transparent">
                  <FontAwesomeIcon icon={faTrash}/> 
                </Button>
                :
                <></>
          }
                </Col>
                </Row>

                </Col>
              </Row>
            )
        }
          
          }
            )}
              <Col  md={3}>
                <button className="btn-transparent-locations" style={{transform: 'translate(-15px,50%)', backgroundImage: `url(${iconAgregar})`, backgroundSize: '1.8em 1.8em', backgroundRepeat: 'no-repeat'    }} onClick={handleOpen}> </button>
              
                <button className="obex-btn btn-green btn btn-primary pl-3" style={{transform: 'translate(0.08em,50%)',}} onClick={handleOpen}>Agregar dirección</button>
              
              </Col>
            </>
            
          }

        </Col>
      </Row>
    </DashLayout>
  )
}

export default Locations;