import React, { FunctionComponent, useState, useEffect } from 'react'; 
import { Container, Row, Col,  Modal, Button, Form, Spinner, Card, Alert, Image } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEdit } from '@fortawesome/free-solid-svg-icons';
import { prependOnceListener } from 'process';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import { parseToCoin } from '../../services/AmountService';
import ObexProjectsRequestHandler from '../../handlers/ObexProjectsRequestsHandler';
import SessionService from '../../services/SessionsService';
import { message } from 'antd';
import axios from 'axios';


type AddonsProps = {
  show: boolean,
  handleClose: any,
  nombreItem: string,
  categoriaItem: string,
  subcategoriaItem: string,
  precioItem: number, 
  idItem: number,
}

const AddItemModal:FunctionComponent<AddonsProps> = props => {

  const { show, handleClose, nombreItem, categoriaItem, subcategoriaItem, precioItem , idItem} = props;


  //const [pricePlanMontly, setPricePlanMontly] = useState(priceMonthly);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [projecstPlanInfo, setProjecstPlanInfo] = useState([]);
  const [requestsPlanInfo, setRequestsPlanInfo] = useState([]);
  const [selectedProject, setProject] = useState(0);
  const [selectedTx, setTx] = useState(0);
  const [projectPlanPriceMonthly, setProjectPriceMonthly] = useState(0);
  const [projectPlanPriceYear, setProjectPriceYear] = useState(0);
  const [txPlanPriceMonthly, setTxPriceMonthly] = useState(0);
  const [txPlanPriceYear, setTxPriceYear] = useState(0);
  const [txDesc, setTxDesc] = useState('');
  const [projectDesc, setProjectDesc] = useState('');
  //var [Message, setMessage] = useState(priceYear);
  const [sending, setSending] = useState(false);
  //var [Subject, setSubject] = useState(priceMonthly);
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [noUpload, setNoUpload] = useState(true);

  const [Nombre, setNombre] = useState('');
  const [Alias, setAlias] = useState('');
  const [Pie, setPie] = useState(0);
  const [Descripcion, seDescripcion] = useState('');
  const [Imagen, seImagen] = useState('');
  const [Sentados, setSentados] = useState(0);

  const [Direccion, setDireccion] = useState('');
  const [CP, setCP] = useState('');
  const [mindist, setMindist] = useState(500);
  const [Position, setPosition] = useState('');

  const history = useHistory();

const Cierra = (e) =>{
  //setMessage('');
  //setSubject('');
  handleClose()
  setNombre('');
  setAlias('')
  seDescripcion('');
  setPie(0);
}

  const changeSubject = (e) => {
    const newSubject = e.target.value;
    //setSubject(newSubject);
  }

  const changeMessage = (e) => {
    const newMessage = e.target.value;
    //setMessage(newMessage);
  }

  const changeCP = (e) => {
    const newMessage = e.target.value;
    setCP(newMessage);
  }

  const changeMD = (e) => {
    const newMessage = e.target.value;
    if(isNaN(newMessage)) setMindist(0);
    else setMindist(newMessage);
  }

  const changeDireccion = (e) => {
    const newMessage = e.target.value;
    setDireccion(newMessage);
  }
  const changePosition = (e) => {
    const newMessage = e.target.value;
    setPosition(newMessage);
  }
  const changeAlias = (e) => {
    const newMessage = e.target.value;
    setAlias(newMessage);
  }

  const changeDescripcion = (e) => {
    const newMessage = e.target.value;
    seDescripcion(newMessage);
  }

  const changePie = (e) => {
    const newMessage = e.target.value;
    setPie(newMessage);
  }

  const changeSentados = (e) => {
    const newMessage = e.target.value;
    setSentados(newMessage);
  }

  const changeNombre = (e) => {
    const newMessage = e.target.value;
    setNombre(newMessage);
  }


  const handleSubmitMessage = async () => {
   

      

    var nombrequery = nombreItem;
    if (Nombre!="") nombrequery = Nombre;
    var aliasquery = categoriaItem;
    if (Alias!="") aliasquery = Alias;
    var descripcionquery = subcategoriaItem;
    if (Descripcion!="") descripcionquery = Descripcion;

    var piequery = precioItem;
    if (Pie!=0) piequery = Pie;
    var sentadoquery ;
    if (Sentados!=0) sentadoquery = Sentados;

    var imagenquery ;
    if (Imagen!='') imagenquery = Imagen;


    if (nombrequery!="" && aliasquery!="" && descripcionquery!="" && piequery>0 ){
      setSending(true);
    if (idItem>=0){

    try{
      const result = await ObexProjectsRequestHandler.get(`/ws/util.py/update_items_presupuestos_eventos?categoria=${aliasquery}&id=${idItem}&nombre=${nombrequery}&precio=${piequery}&subcategoria=${descripcionquery}`);
      var {status,message} = result
      if (status){
        setSuccess("La ubicación se ha modificado con éxito.");

      }
      console.log(result)
      setSending(false);


    } catch (error) {
      console.error('ERROR SENDING MESSAGE ', error);
      setError(error)
    }

  }
  else{

    try{
      const result = await ObexProjectsRequestHandler.get(`/ws/util.py/update_items_presupuestos_eventos?categoria=${aliasquery}&nombre=${nombrequery}&precio=${piequery}&subcategoria=${descripcionquery}`);
      var {status,message} = result
      if (status){
        setSuccess("La ubicación se ha creado con éxito.");

      }
      console.log(result)
      setSending(false);


    } catch (error) {
      console.error('ERROR SENDING MESSAGE ', error);
      setError(error)
    }


  }

    setTimeout(function() { 
      setSuccess('');
      setError('');
      handleClose();

      setNombre('');
      setAlias('')
      seDescripcion('');
      setPie(0);

      }.bind(this), 2000)
    }
  }


////////



const handleUploaded = async (file) =>{
  
  try {
    setSending(true);
    const result = await ObexProjectsRequestHandler.get(`/ws/users.py/data?id_usuario=${SessionService.IdUsuario}&id_sesion=${SessionService.SessionID}&codigo_pais=ES&id_canal=29&url_imagen=http://api.101obex.com/static/${file}&save=true`);
    console.log(result);
    const {status,message} = result
    if (status){
      setSuccess("Datos modificados con éxito.");
      //setMessage('');
      //setSubject('');
    }
    
    setSending(false);
    //handleClose();

  } catch (error) {

    setError(error)
  }
  SessionService.DatosEmpresa["url_imagen"] = `http://api.101obex.com/static/${file}`

  //handleEnName(Subject);
  setTimeout(function() { 
    setSuccess('');
    setError('');
    setNombre('');
    setAlias('')
    seDescripcion('');
    setPie(0);
    handleClose();
    history.push(`/admin`);
    }.bind(this), 2000)

}




const onChange = (event) => {
  const value = event.target.value;

  // this will return C:\fakepath\somefile.ext
  console.log(value);

  const files = event.target.files;

  //this will return an ARRAY of File object
  console.log(files);


  var input = event.target;

  var reader = new FileReader();
  reader.onload = function(){
    var dataURL = reader.result;
    var output = document.getElementById('output') as HTMLImageElement;
    //document.getElementById('output').setAttribute( 'src',dataURL);
    output.src = dataURL.toString();
    console.log(dataURL)
  };
  reader.readAsDataURL(input.files[files.length-1]);

  //setImage(files[(files.length)-1].name);

  setNoUpload(false);

}

function showname () {
  var name = document.getElementById('exampleFormControlFile1') as HTMLInputElement; 
  var file = name.files[(name.files.length)-1];
  const reader = new FileReader();
  var formdata = new FormData()
  formdata.append("image", file);

    axios.post(`http://api.101obex.com:4040/drop_file?file=${name.files[(name.files.length)-1].name}`, formdata, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    handleUploaded(file.name);
};


/////////
  useEffect(() => {

    setNombre(nombreItem);
    setAlias(categoriaItem)
    seDescripcion(subcategoriaItem);
    setPie(precioItem)

  }, [])
  
  return(

    

    <Modal dialogClassName="addons-dialog w-75 obex-dialog py-3 obex-dialog"
      show={show} 
      onHide={Cierra}
      animation={false}>
      <Modal.Header closeButton className="border-0 pb-0 pt-4">
        <Modal.Title className=" w-100">
          <Row>
            <Col className="addons-title" style={{marginTop:'-23px'}}>
              <span >Agregar / Modificar Plato</span>
            </Col>
          </Row>
          <hr style={{marginTop:'3px'}}></hr>
          <Row style={{height:'20px'}}></Row>
          <Row>
            <Col md="10" className="mx-auto  modal-subtitle">

            <Form className="obex-form">

                <Row>
                  <Col>
                  <Row>
                    <Col>
                  Nombre
                  </Col>
                  </Row>
                  <Row>
                <Col>
                <Form.Group controlId="formBasicName">
                  <Form.Control required placeholder="Nombre del plato" onChange={changeNombre} value={Nombre || nombreItem}/>
                </Form.Group>
                </Col>
                </Row>
                </Col>
                </Row>

<Row>
  <Col md={6}>
  Categoria
  </Col>
  <Col md={6}>
  Sub Categoria
  </Col>
</Row>
<Row>
  <Col>
    <Form.Group controlId="formBasicName">
      <Form.Control required placeholder="Categoria" onChange={changeAlias} value={Alias || categoriaItem}/>
    </Form.Group>
  </Col>
  <Col>
    <Form.Group controlId="formBasicName">
      <Form.Control required placeholder="Sub categoria del plato" onChange={changeDescripcion} value={Descripcion || subcategoriaItem}/>
    </Form.Group>
  </Col>
</Row>




                <Row>
                  <Col>
                  <Row>
                    <Col md={3}>
                  Precio
                  </Col>
                  </Row>
                  <Row>

                <Col md={3}>
                
                <Form.Group controlId="formBasicName">
                  <Form.Control required placeholder="De Pie" onChange={changePie} value={Pie || precioItem}/>
                </Form.Group>
                </Col>

                </Row>

                </Col>

                </Row>




            </Form>

            </Col>
          </Row>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pb-4">
        <Container fluid>

          <Row className="mb-2">
            <Col className="text-center">
              
              <Button className="btn-green with-add" onClick={handleSubmitMessage}>
              {sending ? "Agregando / Modificando" : "Agregar / Modificar"}
                {sending && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }} />}
              </Button>
               
            </Col>
          </Row>

          {error.length > 0 && (
                <Alert variant='danger'>
                  {error}
                </Alert>)}
          {success.length > 0 && (
                <Alert variant='success'>
                  {success}
                </Alert>)}   



        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default AddItemModal;