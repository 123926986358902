import DashLayout from '../functionalComponents/DashboardLayout/DashboardLayout';
import React, { FunctionComponent, Component, useEffect, useState } from 'react';
import DashRouters from '../../configs/routers/dashboard-routers.json';
import { Row, Col, Alert, Card, Button, Form, FormControl, Spinner, InputGroup  } from 'react-bootstrap';
import { useHistory,useParams } from 'react-router-dom';
import ObexProjectsRequestHandler from '../../handlers/ObexProjectsRequestsHandler';
import { getToday } from '../../services/TimeService';
import AddTxSelModal from '../Modals/addTxModalSelect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons';

type EventProps = {}


const EventDetail:FunctionComponent<EventProps> = (props) => {

  const history = useHistory();
  const { id } = useParams();
  const today =  getToday();
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [from, setFrom] = useState(today);
  const [username, setUsername] = useState(String());
  const [email, setEmail] = useState(String());
  const [Telefono, setTelefono] = useState(String());
  const [PaymentID, setTPaymentID] = useState(String());
  const [entrantes, setEntrantes] = useState(Array());
  const [opciones, setOpciones] = useState(Array());
  const [principal, setPrincipal] = useState(Array());
  const [pases, setPases] = useState(Array());
  const [extras, setExtras] = useState(Array());
  const [fecha_consulta, setFechaConsulta] = useState(getToday());
  const [active, setActivo] = useState(true);
  const [sending, setSending] = useState(false);
  const [canUpdate, setCanUpdate] = useState(true);
  const [Mail, setMail] = useState('');
  const [Nombre, setName] = useState('');
  const [Direccion, setDireccion] = useState('');
  const [activeUpdate, setActiveUpdate] = useState(false);
  const [habilitado, setHabilitado] = useState(true);
  const [habilitadoL, setHabilitadoL] = useState(true);
  const [habilitadoQ, setHabilitadoQ] = useState(true);
  const [activating,setActivating] = useState(false);
  const [localizating,setLocalizating] = useState(false);
  const [QRblezating,setQRblezating] = useState(false);
  const [stillLoaded, SetStillLoaded] = useState(false);
  const [activeEmployees, setActivos] = useState(0);
  const [localizable, setLocalizable] = useState(false);
  const [QRble, setQRble] = useState(false);
  const [maxEmployees, setMaximos] = useState(0);
  const [canActivate , setCanActivate] = useState(false);
  const [addingTx, setAddingTx ] = useState(false);
  const [locationOptions, setOptionLocation] = useState(Array());
  const [locationDefault, setDefaultLocation] = useState(0);
  const [ActualLocalization,setActualLocalization] = useState(0);
  const [AbledLocations, setAbledLocations] = useState(Array());
  const [LocationtoAdd, setLocationtoAdd] = useState(0);
  const [hoursToday, setHoursToday] = useState('0h 0m');
  const [hoursWeek, setHoursWeek] = useState('0h 0m');
  const [hoursMonth, setHoursMonth] = useState('0h 0m');

  const [Asistentes, setAsistentes] = useState(String());
  const [Localizacion, setLocalizacion] = useState(String());
  const [FranjaHoraria, setFranjaHoraria] = useState(String());
  const [TipoEvento, setTipoEvento] = useState(String());
  const [DescripcionEvento, setDescripcionEvento] = useState(String());
  const [Firma, setFirma] = useState(String());

  const getEvent = async () => {
    setLoading(true);
    SetStillLoaded(true);
    try {
      const { status, evento} = await ObexProjectsRequestHandler.get(`/ws/events_agenda.py/get_evento?id_sesion=${id}`);
      console.log("RESULTADO");
      console.log(status);
      console.log(evento)

      setName(evento.nombre);
      setTPaymentID(evento.id_pago);
      setMail(evento.email);
      setTelefono(evento.telefono);
      setAsistentes(evento.sentados);
      setFranjaHoraria(evento.franja_horaria);
      setLocalizacion(evento.localizacion);
      setTipoEvento(evento.tipo_evento);
      setDescripcionEvento(evento.descripcion);
      setFirma(evento.firma);
      setEntrantes(evento.entrantes);
      setOpciones(evento.opciones);
      setPases(evento.pases);
      setExtras(evento.extras);
      setPrincipal(evento.principales);

    } catch (error) {
      setError(error.message || error)
      setLoading(false);
      SetStillLoaded(false);
    };
    SetStillLoaded(false);
  };



  useEffect(()  => {

    getEvent();
  },[]);


  return(
    <DashLayout sider={DashRouters} active="messages">
      <Row>
        <Col md="12" className="mx-auto">
        <h1>Datos de la Reserva para un {DescripcionEvento}</h1>
        
          <Form>
          <Row>
          <Col md="6">
          <Form.Group className="md-3">
          <Form.Label>Nombre</Form.Label>
          <Form.Control type="text" value={Nombre} disabled={true}/>
          </Form.Group>
          </Col>
          <Col md="2">
          <Form.Group className="md-3">
          <Form.Label>Teléfono</Form.Label>
          <Form.Control  disabled={true} type="text" value={Telefono}/>
          </Form.Group>
          </Col>
          <Col md="4">
          <Form.Group className="md-3">
          <Form.Label>Email</Form.Label>
          <Form.Control type="text" value={Mail}/>
          </Form.Group>
          </Col>
          </Row>

          <Row>
          <Col md="4">
          <Form.Group className="md-3">
          <Form.Label>Localizacion</Form.Label>
          <Form.Control type="text" value={Localizacion}/>
          </Form.Group>
          </Col>
          <Col md="2">
          <Form.Group className="md-3">
          <Form.Label>Asistentes</Form.Label>
          <Form.Control type="text" value={Asistentes}/>
          </Form.Group>
          </Col>

          <Col md="2">
          <Form.Group className="md-3">
          <Form.Label>Franja horaria</Form.Label>
          <Form.Control type="text" value={FranjaHoraria}/>
          </Form.Group>
          </Col>

          <Col md="4">
          <Form.Group className="md-3">
          <Form.Label>Id del Pago</Form.Label>
          <Form.Control type="text" value={PaymentID}/>
          </Form.Group>
          </Col>
          </Row>



          <Row>

          <Col md="12">
          <Form.Group className="md-3">
          <Form.Label>Firma</Form.Label>
          <Form.Control type="text" value={Firma}/>
          </Form.Group>
          </Col>
{/*
          <Col md="4">
          <Form.Group className="md-3">
          <Form.Label>Franja horaria</Form.Label>
          <Form.Control type="text" value={FranjaHoraria}/>
          </Form.Group>
          </Col>
  */}
          </Row>
          </Form>
          <hr/>

          <Row style={{justifyContent:'center'}}>

          {stillLoaded ?
            <Row style={{justifyContent:'center'}}>
              <Col className="mx-auto d-flex align-items-center" style={{ marginTop: '6em', width:'100%' }}>
                <Spinner animation="border"/>
              </Col>
            </Row>:
          <><Col>
         
          {opciones.map(client => {
                                    console.log(client);
                                    console.log(Object.keys(client))
                                    return (
                                      <><Row className="mb-3">
                                        <Col className="">
                                          <span><h1>{Object.keys(client)[0]}</h1></span>
                                        </Col>
                                      </Row><>
                                          {client[Object.keys(client)[0]].map(op => {

                                            console.log(op);

                                            return (
                                              <Row className="mb-3">
                                                <Col className="">
                                                  <span>{op}</span>
                                                </Col>
                                              </Row>
                                            );

                                          })}
                                        </></>
                                                                       
                                   );
                                  }
          )}
              {/*
                <Row>
                  <Col>
                    <h1 className="mb-3">Menu</h1>
                  </Col>
                </Row>

                {stillLoaded ?
                  <Row style={{ justifyContent: 'center' }}>
                    <Col md="12" className="mx-auto d-flex align-items-center" style={{ marginTop: '5em' }}>
                      
                    </Col>
                  </Row>
                  :
                  <><><>
                    {entrantes.length > 0 ?
                      <>
                        <h5>Entrantes</h5>
                        {entrantes.map(client => {
                          return (
                            <Row className="mb-3">
                              <Col className="">
                                <span>{client}</span>
                              </Col>
                            </Row>
                          );
                        }
                        )}</>
                      : <></>}
                  </><>
                      {pases.length > 0 ?
                        <>
                          <h5>Pases</h5>
                          {pases.map(client => {
                            return (
                              <Row className="mb-3">
                                <Col className="">
                                  <span>{client}</span>
                                </Col>
                              </Row>
                            );
                          }
                          )}</>
                        : <></>}
                    </></><>
                      {principal.length > 0 ?
                        <>
                          <h5>Platos Principales</h5>
                          {principal.map(client => {
                            return (
                              <Row className="mb-3">
                                <Col className="">
                                  <span>{client}</span>
                                </Col>
                              </Row>
                            );
                          }
                          )}</>
                        : <></>}
                    </></>}
              </Col><Col md="5">

                  <Row>
                    <Col>
                      <h1 className="mb-3">Servicios Extra</h1>
                    </Col>
                  </Row>

                  {extras.length > 0 ?
                    <>
                      {extras.map(client => {
                        return (
                          <Row className="mb-3">
                            <Col className="">
                              <span>{client}</span>
                            </Col>
                          </Row>
                        );
                      }
                      )}</>
                    : <></>}
*/}
          </Col></>
}
        </Row>
        
        
        
        
        
        </Col>
      </Row>

      

      {addingTx && <AddTxSelModal shown={addingTx} onSuccess={null} onCloseAction={null} maxMonthLimit={100} actualMonthLimit={5}/>}
    </DashLayout>
  )
}

export default EventDetail;