import React, { FunctionComponent, useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Container, Image, Form, Button, Spinner, Alert } from 'react-bootstrap';
import PasswordRecovery from '../Modals/passwordRecovery';
import Logo from '../../assets/img/bannerlagloria.png';
import VerificationNotice from './VerificationNotice';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import SessionService from '../../services/SessionsService';
import Footer from '../functionalComponents/DashboardLayout/FooterMenu';
import './style.css';
import PricingMenu from '../PlansAndPricing/PricingMenu';

type LoginFormProps = {
  userLabel?: string,
  passLabel?: string,
  rememberCheckboxLabel?: string,
  submitButtonLabel?: string,
  missingUserMsg?: string,
  missingPassMsg?: string,
  loginFailedMsg?: string
}

const LoginForm:FunctionComponent<{}> = () => {
  const isAdmin = ():boolean => SessionService.isAdmin();
  const isUser = ():boolean => SessionService.isUser();
  const [mail, setMail] = useState('');
  const [password, setPassword] = useState('');
  const [signining, setSignining] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showRecovery, setShowRecovery] = useState(false);
  const [stillLoaded, SetStillLoaded] = useState(false);

  const handleCloseRecovery = () => setShowRecovery(false);
  const handleShowRecovery = (e) => {
    e.preventDefault(); 
    setShowRecovery(true);
  };

  const history = useHistory();

  function routeDashboard() {
    history.push({
      pathname: '/admin'
    })
  }

  function routeHome() {
    const path = '/';
    window.location.href = path;
  }

  function routePlans() {
    const path = '/Plans';
    window.location.href = path;
  }

  function routePrivate() {
    history.push({
      pathname: '/private'
    })
    
  }

  const redirectPage =  (signindata) =>{

      if (signindata["id_perfil"]==6) routeDashboard();
      else if (signindata["id_perfil"]==1) routePrivate();
      else routePlans();
    
  };

  const handleSubmitSignip = async (event) => {
    event.preventDefault();
    try {
      setError('');
      setSignining(true);
      const signin = await SessionService.login(mail, password);
      SetStillLoaded(true);
      
      if (SessionService.LoginError=="sesion_existente"){
 
        SessionService.logout();
        
        setTimeout(async function() { 
          const signin = await SessionService.login(mail, password);
          setSuccess(true);
          redirectPage(signin);
          }.bind(this), 1000)
          
      } else if (SessionService.LoginError!=""){
        SetStillLoaded(false);
        console.log(SessionService.LoginError)
        setError('An error was ocurred while sigining.');
      } else {
        setSuccess(true);
        redirectPage(signin);
      }
      

    } catch (error) {
      console.log("ERROR EN EL LOGIN ");
      setError('An error was ocurred while sigining.');
      setTimeout(function() { 
        setError('');
        }.bind(this), 2000)

    }
    //setSubmitDisabled(false);
    setSignining(false);
  };


  const handleSubmitSignup = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    routePlans();    
  };


  const changeMail = (e) => {
    const newMail = e.target.value;
    setMail(newMail);
  }

  const changePass = (e) => {
    const newPass = e.target.value;
    setPassword(newPass);
  }

  return(
    
    




    <Container fluid className="text-center d-flex flex-column min-vh-100">
      {/*}
      <PricingMenu
      active="login"
      />
  */}
  <Row style={{minHeight:"15vh"}}>

  </Row>
    {stillLoaded ?
      <Row className="h-100" >
          <Col md="1" className="mx-auto d-flex align-items-center" style={{ marginTop: '25em' }}>
            <Spinner animation="border"/>
          </Col>
        </Row>:
      <><Row className="justify-content-center pt-5 mt-5">
          <div className="border rounded px-5 pt-3 pb-4 login-form">
            <Row className="justify-content-center my-4">
              <Col >
                <Image onClick={routeHome} src={Logo} fluid                             
                            width="100"
                            />
                {/*<h1><b>CENTURIAPP</b></h1>*/}
              </Col>
            </Row>
            <Row>
              <Col>
                <Form className="obex-form">
                  <Form.Group controlId="formBasicEmail">
                    <Form.Control required type="phone" placeholder="Usuario" onChange={changeMail} />
                  </Form.Group>
                  <Form.Group controlId="formBasicPassword">
                    <Form.Control required type="password" placeholder="Contraseña" onChange={changePass} />
                  </Form.Group>
                  <Button type="submit" className="my-2 btn-green btn-block" onClick={handleSubmitSignip} disabled={signining}>
                    {signining ? "Accediendo" : "Acceder"}
                    {signining && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }} />}
                  </Button>
                  {error.length > 0 && (
                    <Alert variant='danger'>
                      {error}
                    </Alert>)}
                </Form>
              </Col>
            </Row>
            <Row>
              <Col className="my-2">
                <a className="link_forgetpass" onClick={handleShowRecovery}>¿Tienes problemas para acceder?</a>
              </Col>
            </Row>
            {/*
    <Row>
      <Col className="my-3">
        <div className="border-top divider-login"></div>
      </Col>
    </Row>
    <Row>
      <Col className="my-2">
      <Form>
          <Button type="submit" variant="light" className="btn-block singin" onClick={handleSubmitSignup}>
            Create account
          </Button>
        </Form>
      </Col>
    </Row>
    */}
          </div>
          <PasswordRecovery show={showRecovery} handleClose={handleCloseRecovery} />
        </Row><VerificationNotice
            mail={mail}
            show={show}
            handleClose={handleClose} /><Row className="mt-auto">
            <Col className="px-0">
              <Footer />
            </Col>
          </Row></> 
}
    </Container>
  );
}

export default LoginForm;