import React, { FunctionComponent, useState, useEffect } from 'react';
import DashLayout from '../../functionalComponents/DashboardLayout/DashboardLayout';
import DashRouters from '../../../configs/routers/dashboard-routers.json';
import { Row, Col, Table, Spinner, Button } from 'react-bootstrap';
import ObexRequestHandler from '../../../handlers/ObexRequestsHandler';
import { parseToCoin } from '../../../services/AmountService';
import ObexProjectsRequestHandler from '../../../handlers/ObexProjectsRequestsHandler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';

type BalanceProps = {

}


const BalanceContent:FunctionComponent<BalanceProps> = (props) => {

  const [payments, setPayments] = useState([]);
  const [stillLoaded, SetStillLoaded] = useState(false);
  const history = useHistory();
  const handleEditMessage = (event, client) => {
  
    event.preventDefault();
    event.stopPropagation();
    console.log("CLIENT")
    console.log(client);
    history.push(`/historial${client.sesion}/data`);

  }



  const getPayments = async () => {
    SetStillLoaded(true);
    try {
      const result = await ObexProjectsRequestHandler.get('/ws/events_agenda.py/get_visit_history');
      const { status, historial } = result;
      if (status) {
        console.log(historial);
        setPayments(historial);
        SetStillLoaded(false);
      } else {
        console.log('Cannot get the account settings information');
        SetStillLoaded(false);
      }
    } catch (error) {
      console.error('ERROR FETCHING ACCOUNT SETTINGS ', error);
      SetStillLoaded(false);
    }
    SetStillLoaded(false);
  }

  useEffect(()=>{
    getPayments()
  }, [])

  return (
    <DashLayout active="balance" sider={DashRouters}>
        <Col md="12" className="mx-auto">
          <Row>
            <Col>
            <h1 className="mb-3">Historial de visitas</h1>
            </Col>
          </Row>
        </Col>
      {stillLoaded ?
        <Row className="h-100" >
          <Col md="1" className="mx-auto d-flex align-items-center" style={{ marginTop: '15em' }}>
            <Spinner animation="border"/>
          </Col>
        </Row>
        :
      <Row>
        <Col>
          <Table responsive>
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Telefono</th>
                <th>correo</th>
                <th>Fecha</th>
                <th>Hora</th>
                <th></th>
              </tr>
            </thead>

            <tbody>
            {payments.map(payment => (
              <tr>
                <td>{payment.nombre}</td>
                <td>{payment.telefono}</td>
                <td>{payment.correo}</td>
                <td>{payment.fecha_registro}</td>
                <td>{payment.hora_registro}</td>
                <td>
                <Button onClick={(event) => handleEditMessage(event, payment)} type="submit" className="btn-transparent">
                  <FontAwesomeIcon icon={faEdit}/> 
                </Button>
                </td>
              </tr>
              ))}
            </tbody>
            
          </Table>
        </Col>
      </Row>
    }
    </DashLayout>
  )
}

export default BalanceContent;