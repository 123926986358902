import React, { FunctionComponent, useEffect, useRef, useState, Fragment } from 'react';
import Logo from '../../../assets/img/bannerlagloria.png';
import { Modal, Navbar, Row, Col, Image, Button, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
//import SelectLanguage from "./SelectLanguage";
import { useHistory } from 'react-router-dom';
import SessionExpired from '../../Modals/sessionExpired';
import InactivityModal from '../../Modals/inactivityModal';
import Cookies from 'universal-cookie';
import SessionService from '../../../services/SessionsService';


const Header:FunctionComponent<{}> = () => {

  const history = useHistory();

  const [isLoggedIn, setLogged] = useState(false);
  const [sessionExpired, setSessionExpired] = useState(false);
  const [inactivity, setInactivity] = useState(false);

  const cookies = new Cookies();

  const handleLogout = (e) => {
    e.preventDefault();
    e.stopPropagation();
    SessionService.logout();
    history.push('/');
  }

  useEffect(() => {
    const user = SessionService.SessionID!="";
    if (user){
      setLogged(true);
    }
  }, [])

  // Como este componente siempre está presente ponemos aquí el listener del inactivityEvent para que lancé el modal
  document.addEventListener('inactivityEvent', (e) => {
    e.preventDefault();
    e.stopPropagation();
    setInactivity(true);
  }, false);

  // Como este componente siempre está presente ponemos aquí el listener del sessionExpiredEvent para que lancé el modal
  document.addEventListener('sessionExpiredEvent', (e) => {
    if (sessionExpired) return;
    e.preventDefault();
    e.stopPropagation();
    setSessionExpired(true);
  }, false);

  const renewSession = async (e) => {
    try {
      e.preventDefault();
      e.stopPropagation();
      await SessionService.renewSession();
      setInactivity(false);
    } catch(error) {
      console.error(error);
    }
  }

  return (
    <Fragment>
      <SessionExpired show={sessionExpired} handleHide={handleLogout}/>
      <InactivityModal show={inactivity && !sessionExpired} renewSession={renewSession} closeSession={handleLogout}/>
      <Row className={'py-0 fixed-top px-2'} id="header">
          <Col md={4} className={'pl-3 my-auto'}>
            <Row className="pl-4">
              <a href="/">
                <img className="" src={Logo} style={{height:'38px'}}/>
                {/*<h1><b className="logo-tempo">CENTURIAPP</b></h1>*/}
              </a>
              </Row>
          </Col>
          <Col className="my-auto">
            <Row className="menu-header">
              {/*
              <Col><a href="/docs" className="link link-header">Docs</a></Col>
              <Col><a href="/apis" className="link link-header">APIs</a></Col>
              <Col><a href="#" className="link link-header">Tools</a></Col>
              <Col><a href="#" className="link link-header">Support</a></Col>
              */}
            </Row>
          </Col>
          {isLoggedIn ? 
            <Col md={1} className="user text-right my-auto">
              <Dropdown className="mr-1 pr-2">
                <Dropdown.Toggle className="link-user" id="obex-user-menu"><FontAwesomeIcon icon={faUserCircle} size="2x"/></Dropdown.Toggle>
                <Dropdown.Menu>
                  {SessionService.TipoUsuario==6 ?
                  <Dropdown.Item onClick={() => history.push('/admin')}>Dashboard</Dropdown.Item>
                  :
                  <Dropdown.Item onClick={() => history.push('/private')}>Dashboard</Dropdown.Item>
                  }
                  <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                </Dropdown.Menu>{' '}
              </Dropdown>
            </Col>:
            <Col md={2} className="login text-right my-auto">
              <a href="/login" className="link-login">Log into Dashboard</a>
            </Col>
          }
          {/* <Col md={1}>
            <SelectLanguage/> 
          </Col>*/}
      </Row>
    </Fragment>
  );
}

export default Header;