import React, { FunctionComponent, useState, useEffect } from 'react'; 
import { Container, Row, Col,  Modal, Button, Form, Spinner, Card, Alert, Image } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEdit, faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { prependOnceListener } from 'process';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import { parseToCoin } from '../../services/AmountService';
import ObexProjectsRequestHandler from '../../handlers/ObexProjectsRequestsHandler';
import SessionService from '../../services/SessionsService';
import { message } from 'antd';
import axios from 'axios';



type AddonsProps = {
  show: boolean,
  handleClose: any,
  handleConfigure: any,
  nombreLoca: string,
  aliasLoca: string,
  descripcionLoca: string,
  imagenLoca: string,
  aforopieLoca: number,
  aforosentadosLoca: number,
  compartidoLoca: boolean,
  idLoca: Array<Object>,
  handleIdLoca: any,
  crearCateg: boolean
}

const AddCategoryModal:FunctionComponent<AddonsProps> = props => {

  const { show, handleClose, handleConfigure, nombreLoca, aliasLoca, descripcionLoca, imagenLoca, aforopieLoca, aforosentadosLoca, compartidoLoca, idLoca, handleIdLoca, crearCateg } = props;


  //const [pricePlanMontly, setPricePlanMontly] = useState(priceMonthly);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [projecstPlanInfo, setProjecstPlanInfo] = useState([]);
  const [requestsPlanInfo, setRequestsPlanInfo] = useState([]);
  const [selectedProject, setProject] = useState(0);
  const [selectedTx, setTx] = useState(0);
  const [projectPlanPriceMonthly, setProjectPriceMonthly] = useState(0);
  const [projectPlanPriceYear, setProjectPriceYear] = useState(0);
  const [txPlanPriceMonthly, setTxPriceMonthly] = useState(0);
  const [txPlanPriceYear, setTxPriceYear] = useState(0);
  const [txDesc, setTxDesc] = useState('');
  const [projectDesc, setProjectDesc] = useState('');
  //var [Message, setMessage] = useState(priceYear);
  const [sending, setSending] = useState(false);
  //var [Subject, setSubject] = useState(priceMonthly);
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [noUpload, setNoUpload] = useState(true);

  const [Nombre, setNombre] = useState('');
  const [Alias, setAlias] = useState('');
  const [Pie, setPie] = useState(0);
  const [Descripcion, seDescripcion] = useState('');
  const [Imagen, seImagen] = useState('');
  const [Sentados, setSentados] = useState(0);
  const [Compartido, setCompartido] = useState(false);

  const [Direccion, setDireccion] = useState('');
  const [CP, setCP] = useState('');
  const [mindist, setMindist] = useState(500);
  const [Position, setPosition] = useState('');

  const [selectedTipo, setSelectedTipo] = useState('');

  const [TipoOptions, setTipoOptions] = useState(Array());

  const history = useHistory();

const Cierra = (e) =>{
  //setMessage('');
  //setSubject('');

  setAlias('');
  setNombre('');
  seDescripcion('');
  setSentados(0);
  setPie(0);

  handleClose()
}



const handleEraseOption = async (event, client) => {
  console.log(client);
  var idLocaTemp = [];
  idLoca.map(loca =>{
    if (loca["tipo"]==client.tipo && loca["asterisco"]==client.asterisco && loca["cantidad"]==client.cantidad 
      && loca["precio_base"]==client.precio_base && loca["descripcion"]==client.descripcion){

      } else {
        idLocaTemp.push(loca);
      }

  });
  handleIdLoca(idLocaTemp)
}


const HandleSelectTipo = async (e) =>{
  console.log(e.target.value);
  setSelectedTipo(e.target.value);
}

  const changeSubject = (e) => {
    const newSubject = e.target.value;
    //setSubject(newSubject);
  }

  const changeMessage = (e) => {
    const newMessage = e.target.value;
    //setMessage(newMessage);
  }

  const changeCP = (e) => {
    const newMessage = e.target.value;
    setCP(newMessage);
  }

  const changeMD = (e) => {
    const newMessage = e.target.value;
    if(isNaN(newMessage)) setMindist(0);
    else setMindist(newMessage);
  }

  const changeDireccion = (e) => {
    const newMessage = e.target.value;
    setDireccion(newMessage);
  }
  const changePosition = (e) => {
    const newMessage = e.target.value;
    setPosition(newMessage);
  }
  const changeAlias = (e) => {
    const newMessage = e.target.value;
    setAlias(newMessage);
  }

  const changeDescripcion = (e) => {
    const newMessage = e.target.value;
    seDescripcion(newMessage);
  }

  const changePie = (e) => {
    const newMessage = e.target.value;
    setPie(newMessage);
  }

  const changeSentados = (e) => {
    const newMessage = e.target.value;
    setSentados(newMessage);
  }

  const changeConfigured = (e) => {
    const newMessage = e.target.value;
    handleConfigure();
    //setCompartido(!Compartido);
  }

const [DescOption, setDescOption] = useState('');
  const changeDescOption = (e) => {
    const newMessage = e.target.value
    setDescOption(newMessage);
  }
  const [BaseOption, setBaseOption] = useState(0.0);
  const changeBaseOption = (e) => {
    const newMessage = e.target.value
    setBaseOption(newMessage);
  }
  const [AstOption, setAstOption] = useState(0.0);
  const changeAstOption = (e) => {
    const newMessage = e.target.value
    setAstOption(newMessage);
  }
  const [CantOption, setCantOption] = useState(0);
  const changeCantOption = (e) => {
    const newMessage = e.target.value
    setCantOption(newMessage);
  }

  const handleAddOption = async (event) => {
    if (DescOption!="") {
      console.log("AÑADIENDO");

      setDescOption("");
      setBaseOption(0.0);
      setAstOption(0.0);
      setCantOption(0.0);
      console.log(idLoca);

      var idLocaTemp = idLoca;
      idLocaTemp.push({'asterisco':AstOption,'cantidad':CantOption,'descripcion':DescOption,'muestra_precio':false,'precio_base':BaseOption,'tipo':selectedTipo})
      handleIdLoca(idLocaTemp);
    }
  }

  const changeNombre = (e) => {
    const newMessage = e.target.value;
    setNombre(newMessage);

  }


  const handleSubmitMessage = async () => {
   

      

    var nombrequery = nombreLoca;
    if (Nombre!="") nombrequery = Nombre;
    var aliasquery = aliasLoca;
    if (Alias!="") aliasquery = Alias;
    var descripcionquery = descripcionLoca;
    if (Descripcion!="") descripcionquery = Descripcion;

    var piequery = aforopieLoca;
    if (Pie!=0) piequery = Pie;
    var sentadoquery = aforosentadosLoca;
    if (Sentados!=0) sentadoquery = Sentados;

    var imagenquery = imagenLoca;
    if (Imagen!='') imagenquery = Imagen;

    var compartidoquerry = compartidoLoca;
    if (Compartido!=compartidoquerry) compartidoquerry = Compartido;

    if (nombrequery!=""  && descripcionquery!="" && piequery+sentadoquery>0 ){
      setSending(true);

    var idLocaLlamada = "";

    if ((compartidoLoca || Compartido) == false) idLocaLlamada = ""; else {
      console.log("Hay configuracion")
      idLocaLlamada=`{"config":[`
      idLoca.map(op=>{
        idLocaLlamada+=`{"descripcion":"${op["descripcion"]}","tipo":"${op["tipo"]}","cantidad":${op["cantidad"]},"muestra_precio":false,"precio_base":${op["precio_base"]},"asterisco":${op["asterisco"]}}`;
      });
      idLocaLlamada+="]}";
    }

    if (/*idLoca>=0*/true){

    try{
      
      if (crearCateg){
        if (aliasquery==""){
          if (!compartidoLoca){
          var result = await ObexProjectsRequestHandler.get(`/ws/events_agenda.py/add_category?name=${nombrequery}&description=${descripcionquery}&price=${sentadoquery}&config=${idLocaLlamada}`);
        } else {
          console.log("uno")
          console.log(idLocaLlamada)
        }
      } else {
        console.log("dos")
        if (!compartidoLoca){
          var result = await ObexProjectsRequestHandler.get(`/ws/events_agenda.py/add_category?name=${nombrequery}&description=${descripcionquery}&price=${sentadoquery}&parent=${aliasquery}`);
        } else {
          console.log("tres")
          console.log(idLocaLlamada)
          var result = await ObexProjectsRequestHandler.get(`/ws/events_agenda.py/add_category?name=${nombrequery}&description=${descripcionquery}&price=${sentadoquery}&parent=${aliasquery}&config=${idLocaLlamada}`);
        }

      }

    } else {
      //var result = await ObexProjectsRequestHandler.get(`/ws/events_agenda.py/add_category?name=${nombrequery}&description=${descripcionquery}&price=${sentadoquery}&parent=${aliasquery}`);
      var url = `ws/events_agenda.py/update_category?name=${nombrequery}&configuration=${idLocaLlamada}&description=${descripcionquery}&price=${sentadoquery}&main_category=${aliasquery==""}&parent=${aliasquery}&final=${(compartidoLoca || Compartido)}`
      console.log(url);
      var result = await ObexProjectsRequestHandler.get(`/ws/events_agenda.py/update_category?name=${nombrequery}&configuration=${idLocaLlamada}&description=${descripcionquery}&price=${sentadoquery}&main_category=${aliasquery==""}&parent=${aliasquery}&final=${(compartidoLoca || Compartido)}`);
    
    }
      
      var {status,message} = result
      if (status){
        setSuccess("La categoria se ha modificado con éxito.");
        //setMessage('');
        //setSubject('');
      }
      console.log(result)
      setSending(false);
      //handleClose();

    } catch (error) {
      console.error('ERROR SENDING MESSAGE ', error);
      setError(error)
    }

  }
  else{

    try{
      const result = await ObexProjectsRequestHandler.get(`/ws/events_agenda.py/update_localizaciones?aforo_sentado=${sentadoquery}&aforo_pie=${piequery}&nombre=${nombrequery}&alias=${aliasquery}&descripcion=${descripcionquery}&compartido=${compartidoquerry}&imagen=${imagenquery}`);
      var {status,message} = result
      if (status){
        setSuccess("La ubicación se ha creado con éxito.");
        //setMessage('');
        //setSubject('');
      }
      console.log(result)
      setSending(false);
      //handleClose();

    } catch (error) {
      console.error('ERROR SENDING MESSAGE ', error);
      setError(error)
    }


  }

    setTimeout(function() { 
      setSuccess('');
      setError('');
      setAlias('');
      setNombre('');
      seDescripcion('');
      setSentados(0);
      setPie(0);
      handleClose();
      }.bind(this), 2000)
    }
  }


////////



const handleUploaded = async (file) =>{
  
  try {
    setSending(true);
    const result = await ObexProjectsRequestHandler.get(`/ws/users.py/data?id_usuario=${SessionService.IdUsuario}&id_sesion=${SessionService.SessionID}&codigo_pais=ES&id_canal=29&url_imagen=http://api.101obex.com/static/${file}&save=true`);
    console.log(result);
    const {status,message} = result
    if (status){
      setSuccess("Datos modificados con éxito.");
      //setMessage('');
      //setSubject('');
    }
    
    setSending(false);
    //handleClose();

  } catch (error) {

    setError(error)
  }
  SessionService.DatosEmpresa["url_imagen"] = `http://api.101obex.com/static/${file}`

  //handleEnName(Subject);
  setTimeout(function() { 
    setSuccess('');
    setError('');
    setAlias('');
    setNombre('');
    seDescripcion('');
    setSentados(0);
    setPie(0);
    handleClose();
    history.push(`/admin`);
    }.bind(this), 2000)

}


const getOptionsOptions = async () =>{
  try {
    const { status, result} = await ObexProjectsRequestHandler.get(`/ws/events_agenda.py/get_item_categories`);
    const optionCategory = result.map(value => <option value={value.nombre}>{value.nombre}</option>)
    setTipoOptions(optionCategory)
    setSelectedTipo(result[0].nombre)
  }catch{

  }



}

const onChange = (event) => {
  const value = event.target.value;

  // this will return C:\fakepath\somefile.ext
  console.log(value);

  const files = event.target.files;

  //this will return an ARRAY of File object
  console.log(files);


  var input = event.target;

  var reader = new FileReader();
  reader.onload = function(){
    var dataURL = reader.result;
    var output = document.getElementById('output') as HTMLImageElement;
    //document.getElementById('output').setAttribute( 'src',dataURL);
    output.src = dataURL.toString();
    console.log(dataURL)
  };
  reader.readAsDataURL(input.files[files.length-1]);

  //setImage(files[(files.length)-1].name);

  setNoUpload(false);

}

useEffect(()  => {
  getOptionsOptions();
},[]);

/////////

  
  return(

    

    <Modal dialogClassName="addons-dialog w-75 obex-dialog py-3 obex-dialog"
      show={show} 
      onHide={Cierra}
      animation={false}>
      <Modal.Header closeButton className="border-0 pb-0 pt-4">
        <Modal.Title className=" w-100">
          <Row>
            <Col className="addons-title" style={{marginTop:'-23px'}}>
              { crearCateg ?
              <span >Agregar Categoría</span>:
              <span >Modificando Categoría</span>
              }
            </Col>
          </Row>
          <hr style={{marginTop:'3px'}}></hr>
          <Row style={{height:'20px'}}></Row>
          <Row>
            <Col md="10" className="mx-auto  modal-subtitle">

            <Form className="obex-form">

                <Row>
                  <Col>
                  <Row>
                    <Col>
                  Nombre
                  </Col>
                  {(aliasLoca!="" && aliasLoca!=null) ?
                  <Col>
                  Categoria padre
                  </Col>:<></>
}
                  </Row>
                  <Row>
                <Col>
                <Form.Group controlId="formBasicName1">
                  <Form.Control required placeholder="Nombre de la categoria" onChange={changeNombre} value={Nombre || nombreLoca}/>
                </Form.Group>
                </Col>
                <Col>
                {(aliasLoca!="" && aliasLoca!=null) ?
                <Form.Group controlId="formBasicName">
                  <Form.Control required placeholder="Sin categoria Padre" onChange={changeAlias} value={aliasLoca} disabled={crearCateg}/>
                </Form.Group>:<div style={{textAlign:'center'}}>Categoria Principal</div>
                }

                </Col>
                </Row>
                </Col>
                </Row>


                <Row>
                <Col>
                Descripción
                <Form.Group controlId="formBasicName">
                  <Form.Control required placeholder="Descripción de la categoria" onChange={changeDescripcion} value={Descripcion || descripcionLoca}/>
                </Form.Group>
                </Col>
                </Row>

                <Row style={{justifyContent:'center'}}>
                  <Col>
                  <Row>
                    <Col>
                  Precio Base
                  </Col>
                  <Col style={{textAlign:'center'}}>
                  Categoria Final
                  </Col>

                  </Row>
                  <Row>
                <Col>
                <Form.Group controlId="formBasicName" >
                  <Form.Control required placeholder="Sentados" onChange={changeSentados} value={Sentados || aforosentadosLoca}/>
                </Form.Group>
                </Col>


            <Col style={{textAlign:'center'}}>
              <Form.Check
                type={'checkbox'}
                label={""}
                id={"activo"}
                checked={Compartido || compartidoLoca}
                disabled={false}
                className=""
                
                onClick={changeConfigured}
                style={{marginTop:'0.5rem'}}
              />
            </Col>


                </Row>
                {Compartido || compartidoLoca ?

                  <><span>Configuración</span><Row style={{ backgroundColor:'lightgray', minHeight:'140px'}}>
                    <Col>
                    <Row style={{ backgroundColor:'gray'}}>
                    <Col style={{fontSize:"13px"}}>Tipo</Col><Col style={{fontSize:"13px"}}>Descripcion</Col><Col style={{fontSize:"13px"}}>Cantidad</Col><Col style={{fontSize:"13px"}}>Precio Base</Col><Col style={{fontSize:"13px"}}>Precio *</Col><Col md="1"></Col>
                    </Row>
                      <div>{idLoca.map(conff =>{

                        return(
                          <Row>
                          
                          <Col style={{fontSize:"13px"}}>{conff["tipo"]}</Col>
                          <Col style={{fontSize:"13px"}}>{conff["descripcion"]}</Col>
                          <Col style={{fontSize:"13px"}}>{conff["cantidad"]}</Col>
                          <Col style={{fontSize:"13px"}}>{conff["precio_base"]}</Col>
                          <Col style={{fontSize:"13px"}}>{conff["asterisco"]}</Col>
                          <Col md="1">

                          <div onClick={(event) => handleEraseOption(event, conff)} >
                            <FontAwesomeIcon icon={faTrash} size='xs'/>
                          </div>

                          </Col>
                          
                          </Row>
                        )

                        })


                        }</div>
                        </Col>
                        </Row>
                        <Row style={{backgroundColor:'#808080a8'}}>
                          <Col>

                          {/*<Form.Control required placeholder="Tipo" onChange={changeAlias} value={aliasLoca} style={{fontSize:'13px', backgroundColor:'transparent', height:'22px',borderRadius:'0px',marginBottom:'2px',borderTop:'none',borderRight:'none',borderLeft:'none', color:'white'}}/>*/}
                            <Form.Control onChange={HandleSelectTipo} as="select" custom value={selectedTipo} style={{fontSize:'13px', backgroundColor:'transparent', height:'22px',borderRadius:'0px',marginBottom:'2px',borderTop:'none',borderRight:'none',borderLeft:'none', color:'white'}}>
                              {TipoOptions}
                            </Form.Control>
                          </Col>
                          <Col>
                          <Form.Control required placeholder="Descripcion" onChange={changeDescOption} value={DescOption} style={{fontSize:'13px', backgroundColor:'transparent', height:'22px',borderRadius:'0px',marginBottom:'2px',borderTop:'none',borderRight:'none',borderLeft:'none', color:'white'}}/>
                          </Col>
                          <Col>
                          <Form.Control required placeholder="Cantidad" onChange={changeCantOption} value={CantOption} style={{fontSize:'13px', backgroundColor:'transparent', height:'22px',borderRadius:'0px',marginBottom:'2px',borderTop:'none',borderRight:'none',borderLeft:'none', color:'white'}}/>
                          </Col>
                          <Col>
                          <Form.Control required placeholder="Precio base" onChange={changeBaseOption} value={BaseOption} style={{fontSize:'13px', backgroundColor:'transparent', height:'22px',borderRadius:'0px',marginBottom:'2px',borderTop:'none',borderRight:'none',borderLeft:'none', color:'white'}}/>
                          </Col>
                          <Col>
                          <Form.Control required placeholder="Precio *" onChange={changeAstOption} value={AstOption} style={{fontSize:'13px', backgroundColor:'transparent', height:'22px',borderRadius:'0px',marginBottom:'2px',borderTop:'none',borderRight:'none',borderLeft:'none', color:'white'}}/>
                          </Col>
                          <Col md="1">
                          <div><FontAwesomeIcon icon={faPlusCircle} size='xs' onClick={handleAddOption}/> </div>
                          </Col>
                        </Row>
                  </>
                  :
                  <>

                  </>
                  
                }
                
                </Col>

                </Row>
                


            </Form>

            </Col>
          </Row>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pb-4">
        <Container fluid>

          <Row className="mb-2">
            <Col className="text-center">
              
              <Button className="btn-green with-add" onClick={handleSubmitMessage}>

            { crearCateg ?
              <>{sending ? "Agregando" : "Agregar"}</>:
              <>{sending ? "Modificando" : "Modificar"}</>
            }
                
                {sending && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }} />}
              </Button>
               
            </Col>
          </Row>

          {error.length > 0 && (
                <Alert variant='danger'>
                  {error}
                </Alert>)}
          {success.length > 0 && (
                <Alert variant='success'>
                  {success}
                </Alert>)}   



        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default AddCategoryModal;