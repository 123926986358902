import React, { FunctionComponent, useState, useEffect, Fragment } from 'react';
import { Accordion, Nav, Row, Col, Image, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCode, faUserCircle, faChartBar, faMoneyBillAlt, faPaperPlane, faHandLizard } from '@fortawesome/fontawesome-free-regular';
import { useLocation } from 'react-router-dom'
import Cookies from 'universal-cookie/cjs/Cookies';
import SupportBanner from '../../DashboardComponents/supportBanner';


// ESTE ES EL SIDEBAR DE DASHBOARD(UNA VEZ LOGUEADO ETC)

type SideProps = {
  sider: any,
  active?: string,
  sublinks?: Array<any>
};

const DashboardSideBar:FunctionComponent<SideProps> = (props) => {

  const { sider, active, sublinks } = props;
  
  const history = useHistory();

  const { urlBase, menus } = sider;
  const urlPathName = window.location.pathname;
  const [navMenu, setNav] = useState([]);
  const [expanded, setExpanded] = useState('');

  const loadNav = () => {
    const cookies = new Cookies();
    const sessionCookie = cookies.get('101Obex');
    let showAdmin = false;
    if (sessionCookie && sessionCookie.admin) showAdmin = true;
    const navs = menus.map((nav) => {
      let { id, links, basePath, icon, title } = nav;
      if (active === id && sublinks) links = links.concat(sublinks);
      return (
        <Nav className="flex-column mb-2 pl-4">
          <div className={'px-0 title-nav'}>
            <div className={`${active == id ? 'link-active': ''}`} onClick={() => history.push(basePath, {})}>
              {nav.icon &&
                <img className={'pr-2'} src={`/src/assets/img/iconosv2/icon-${icon}.svg`} />
              }
              {title}
            </div>
          </div>
          {(expanded === id || active === id) && ( 
            // si el expandido desde la raiz es el id o el activo mandado por props lo es expandimos el menú
            <div>
              <Nav.Item className="ml-4">
                {links.map(link => {
                  const { title, path, separation, icon } = link;
                  const url = `${basePath}/${path}`;

                  return (
                    <Fragment>
                      <Nav.Link className={`pl-2 ${urlPathName == url ? 'link-active' : ''}`} onClick={() => history.push(url, {})}>
                        {icon &&
                          <img className={'pr-2'} src={`/src/assets/img/iconosv2/icon-${icon}.png`} />
                        }
                        <span>{title}</span>
                      </Nav.Link>
                    </Fragment>
                  );
                })}
              </Nav.Item>
            </div>
          )}
        </Nav>
      )
    })
    setNav(navs);
  }


  const setExpandedByBasePath = () => {
    const { pathname } = location;
    const expanded = pathname.split('/')[1]
    setExpanded(expanded);
  }

  useEffect(()=> {
    loadNav();
    setExpandedByBasePath();
  }, []);

  useEffect(()=> {
    loadNav();
  }, [sublinks]);

  return (
    <div id="sidebar" className="pt-4 d-flex flex-column min-vh-100 border-right">
      {navMenu}
{/*      <SupportBanner/>*/}
    </div>
  )
}

export default DashboardSideBar;