import React, { FunctionComponent, Component, useEffect, useState } from 'react';
import DashLayout from '../functionalComponents/DashboardLayout/DashboardLayout';
import DashRouters from '../../configs/routers/dashboard-routers.json';
import { Row, Col, Alert, Card, Button, Form, FormControl, Spinner, InputGroup  } from 'react-bootstrap';
import ObexRequestsHandler from '../../handlers/ObexRequestsHandler';
import { useHistory } from 'react-router-dom';
import ObexProjectsRequestHandler from '../../handlers/ObexProjectsRequestsHandler';
import { faAddressCard, faBan, faBandAid, faEdit, faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { parseToNumberFormat } from '../../services/AmountService';
import iconAgregar from '../../assets/img/agregar-dirección2x.png';
import AddCategoryModal from './AddCategoryModal';
import { Children } from 'preact/compat';

type ClientsProps = {}

const CategoryMaker:FunctionComponent<ClientsProps> = (props) => {

  const history = useHistory();
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clients, setClients] = useState(Array());
  const [stillLoaded, SetStillLoaded] = useState(false);
  const [show, setShow] = useState(false);
  const [idLoca, setidLoca] = useState([{}]);
  const [crearCategory, setCrearCategory] = useState(true);
  const [nombreLoca, setnombreLoca] = useState('');
  const [aliasLoca, setaliasLoca] = useState('');
  const [descripcionLoca, setdescripcionLoca] = useState('');
  const [imagenLoca, setimagenLoca] = useState('');
  const [aforopieLoca, setaforopieLoca] = useState(0);
  const [aforosentadosLoca, setaforosentadosLoca] = useState(0);
  const [nombrecompartidoLocaLoca, setcompartidoLoca] = useState(false);
   
  const handleClose = () => {
    getCategories();
    setShow(false);
  }

  const changeCompartido = () => {
    setcompartidoLoca(!nombrecompartidoLocaLoca);
  }

  const handleIdLoca = (opciones) =>{

    setidLoca(opciones);

  }

  const handleAddLocationChild = (event, Children ) =>{

    console.log(Children);

    setnombreLoca('');
    setaliasLoca(Children.nombre);
    setdescripcionLoca('');
    setimagenLoca('');
    setaforopieLoca(0);
    setaforosentadosLoca(0);
    setcompartidoLoca(false);
    setidLoca([{}]);
    setCrearCategory(true);

    setShow(true);
    

  }

  const handleCreateMainCategorie = () => {
   
    setnombreLoca('');
    setaliasLoca('');
    setdescripcionLoca('');
    setimagenLoca('');
    setaforopieLoca(0);
    setaforosentadosLoca(0);
    setcompartidoLoca(false);
    setidLoca([{}]);
    setCrearCategory(true);

    setShow(true);
  }

  const getCategories = async () => {
    setLoading(true);
    SetStillLoaded(true);
    try {
      const {status, result, message } = await ObexProjectsRequestHandler.get(`/ws/events_agenda.py/get_main_categories`);

      if (!status) throw { message };
      
      setClients(result);


    } catch (error) {
      setError(error.message || error)
      setLoading(false);
      SetStillLoaded(false);
    };
    SetStillLoaded(false);
  };


  const handleEditLocation = async (event, client) => {

    setnombreLoca(client.nombre);
    setaliasLoca(client.parent);
    setdescripcionLoca(client.descripcion);
    setimagenLoca(client.imagen);
    setaforopieLoca(client.principal);
    setaforosentadosLoca(client.precio_base);
    setcompartidoLoca(client.final);
    setCrearCategory(false);

    if (client.configuration == null) setidLoca([{}]); else setidLoca(client.configuration.config);

    setShow(true)

  }

  const handleEraseLocation = async (event, client) => {

    const {status, message} = await ObexProjectsRequestHandler.get(`/ws/events_agenda.py/erase_category?category=${client.nombre}`);
    if (status){
      getCategories();
      }
      else {

      setTimeout(function() { 

      }.bind(this), 2000)

      SetStillLoaded(false);
      }
    SetStillLoaded(false);

  }

  useEffect(()  => {
    getCategories();
  },[]);


  return(
    <DashLayout sider={DashRouters} active="locations">

      {/*} nombreLoca, aliasLoca, descripcionLoca, imagenLoca, aforopieLoca, aforosentadosLoca, compartidoLoca */}
    <AddCategoryModal
      show={show}
      handleClose={handleClose}
      handleConfigure={changeCompartido}
      nombreLoca={nombreLoca}
      aliasLoca={aliasLoca}
      descripcionLoca={descripcionLoca}
      imagenLoca={imagenLoca} 
      aforopieLoca={aforopieLoca}
      aforosentadosLoca={aforosentadosLoca}
      compartidoLoca={nombrecompartidoLocaLoca}
      handleIdLoca={handleIdLoca}
      idLoca={idLoca}

      crearCateg={crearCategory}
      />


      <Row>
      <Col md="9" className="mx-auto" style={{marginBottom:'40px'}}>
          
        <Row>
            <Col md={9}>
              <h1 className="mb-3">Listado de Categorias de Eventos</h1>
              </Col>
              <Col  md={3}>
              <button className="btn-transparent" style={{ float: 'right', transform: 'translate(-15px,50%)'}}><FontAwesomeIcon icon={faPlusCircle} onClick={handleCreateMainCategorie}/> </button>
              </Col>
        </Row>

          

          <Row className="mb-3 border-bottom">
            <Col>Nombre</Col>
            <Col>Descripcion</Col>
            <Col md="1">Precio base</Col>
            <Col md="2">Acción</Col>
          </Row>

          {stillLoaded ?
        <Row className="h-100" >
          <Col md="1" className="mx-auto d-flex align-items-center" style={{ marginTop: '15em' }}>
            <Spinner animation="border"/>
          </Col>
        </Row>
        :
        <>

          {clients.map(client => { 
            if (true){
              return (
                <><Row className="mt-3">
                  <Col className="">
                    {true ? <span><h6>{client.nombre}</h6></span> : <span>{client.nombre}</span>}
                  </Col>
                  <Col className="">
                    {true ? <span><h6>{client.descripcion}</h6></span> : <span>{client.descripcion}</span>}
                  </Col>
                  <Col md="1">
                    {/*{ client.central ? <span><h6>{parseToNumberFormat(client.mindist)} m</h6></span>:<span>{parseToNumberFormat(client.mindist)} m</span>}*/}
                    {true ? <span><h6>{client.precio_base}</h6></span> : <span>{client.precio_base}</span>}
                  </Col>


                  <Col md="2">

                    <Row>

                      <Col md="2">
                        {!client.central ?
                          <Button onClick={(event) => handleEditLocation(event, client)} type="submit" className="btn-transparent">
                            <FontAwesomeIcon icon={faEdit} />
                          </Button>
                          :
                          <></>}
                      </Col>
                      <Col md="2">
                        {true ?
                          <Button onClick={(event) => handleEraseLocation(event, client)} type="submit" className="btn-transparent">
                            <FontAwesomeIcon icon={faTrash} />
                          </Button>
                          :
                          <></>}
                      </Col>

                      <Col md="2">
                        {!client.final ?
                          <Button onClick={(event) => handleAddLocationChild(event, client)} type="submit" className="btn-transparent">
                            <FontAwesomeIcon icon={faPlusCircle} />
                          </Button>
                          :
                          <></>}
                      </Col>


                    </Row>

                  </Col>
                </Row><>
                    {true  ?
                      <>
                        {client.childs.map(children => {
                          return (
                            <><>
                              {children.indice == 0 ?
                                <Row>
                                  <Col>
                                  <div style={{ marginLeft: '40px' , fontSize:'14px'}}>{children.nombre}</div>
                                  </Col>
                                  <Col><div style={{ fontSize:'14px'}}>{children.descripcion}</div></Col>
                                  <Col md="1"><div style={{ fontSize:'14px'}}>{children.precio_base}</div></Col>
                                  
                                  <Col md="2"><div style={{ fontSize:'14px'}}>
                                  <Row>
                                    <Col md="2">
                                      {!client.central ?
                                        <Button onClick={(event) => handleEditLocation(event, children)} type="submit" className="btn-transparent">
                                          <FontAwesomeIcon icon={faEdit} />
                                        </Button>
                                        :
                                        <></>}
                                    </Col>
                                    <Col md="2">
                                      {true ?
                                        <Button onClick={(event) => handleEraseLocation(event, children)} type="submit" className="btn-transparent">
                                          <FontAwesomeIcon icon={faTrash} />
                                        </Button>
                                        :
                                        <></>}
                                    </Col>

                                    <Col md="2">
                                      {!children.final ?
                                        <Button onClick={(event) => handleAddLocationChild(event, children)} type="submit" className="btn-transparent">
                                          <FontAwesomeIcon icon={faPlusCircle} />
                                        </Button>
                                        :
                                        <></>}
                                    </Col>
                                    </Row>
                                    </div></Col>
                                </Row>
                                :
                                <></>}</><>
                                {children.indice == 1 ?
                                <Row>
                                <Col>
                                <div style={{ marginLeft: '60px' , fontSize:'14px'}}>{children.nombre}</div>
                                </Col>
                                <Col><div style={{ fontSize:'14px'}}>{children.descripcion}</div></Col>
                                <Col md="1"><div style={{ fontSize:'14px'}}>{children.precio_base}</div></Col>
                                <Col md="2"><div style={{ fontSize:'14px'}}>
                                <Row>
                                  <Col md="2">
                                    {!client.central ?
                                      <Button onClick={(event) => handleEditLocation(event, children)} type="submit" className="btn-transparent">
                                        <FontAwesomeIcon icon={faEdit} />
                                      </Button>
                                      :
                                      <></>}
                                  </Col>
                                  <Col md="2">
                                    {true ?
                                      <Button onClick={(event) => handleEraseLocation(event, children)} type="submit" className="btn-transparent">
                                        <FontAwesomeIcon icon={faTrash} />
                                      </Button>
                                      :
                                      <></>}
                                  </Col>

                                  <Col md="2">
                                    {!children.final ?
                                      <Button onClick={(event) => handleAddLocationChild(event, children)} type="submit" className="btn-transparent">
                                        <FontAwesomeIcon icon={faPlusCircle} />
                                      </Button>
                                      :
                                      <></>}
                                  </Col>
                                  </Row>
                                  </div></Col>
                              </Row>
                                  :
                                  <></>}</><>
                                {children.indice == 2 ?
                                <Row>
                                <Col>
                                <div style={{ marginLeft: '70px' , fontSize:'14px'}}>{children.nombre}</div>
                                </Col>
                                <Col><div style={{ fontSize:'14px'}}>{children.descripcion}</div></Col>
                                <Col md="1"><div style={{ fontSize:'14px'}}>{children.precio_base}</div></Col>
                                <Col md="2"><div style={{ fontSize:'14px'}}>
                                <Row>
                                  <Col md="2">
                                    {!client.central ?
                                      <Button onClick={(event) => handleEditLocation(event, children)} type="submit" className="btn-transparent">
                                        <FontAwesomeIcon icon={faEdit} />
                                      </Button>
                                      :
                                      <></>}
                                  </Col>
                                  <Col md="2">
                                    {true ?
                                      <Button onClick={(event) => handleEraseLocation(event, children)} type="submit" className="btn-transparent">
                                        <FontAwesomeIcon icon={faTrash} />
                                      </Button>
                                      :
                                      <></>}
                                  </Col>

                                  <Col md="2">
                                    {!children.final ?
                                      <Button onClick={(event) => handleAddLocationChild(event, children)} type="submit" className="btn-transparent">
                                        <FontAwesomeIcon icon={faPlusCircle} />
                                      </Button>
                                      :
                                      <></>}
                                  </Col>
                                  </Row>
                                  </div></Col>
                              </Row>
                                  :
                                  <></>}</><>
                                {children.indice == 3 ?
                                <Row>
                                <Col>
                                <div style={{ marginLeft: '80px' , fontSize:'14px'}}>{children.nombre}</div>
                                </Col>
                                <Col><div style={{ fontSize:'14px'}}>{children.descripcion}</div></Col>
                                <Col md="1"><div style={{ fontSize:'14px'}}>{children.precio_base}</div></Col>
                                <Col md="2"><div style={{ fontSize:'14px'}}>
                                <Row>
                                  <Col md="2">
                                    {!client.central ?
                                      <Button onClick={(event) => handleEditLocation(event, children)} type="submit" className="btn-transparent">
                                        <FontAwesomeIcon icon={faEdit} />
                                      </Button>
                                      :
                                      <></>}
                                  </Col>
                                  <Col md="2">
                                    {true ?
                                      <Button onClick={(event) => handleEraseLocation(event, children)} type="submit" className="btn-transparent">
                                        <FontAwesomeIcon icon={faTrash} />
                                      </Button>
                                      :
                                      <></>}
                                  </Col>

                                  <Col md="2">
                                    {!children.final ?
                                      <Button onClick={(event) => handleAddLocationChild(event, children)} type="submit" className="btn-transparent">
                                        <FontAwesomeIcon icon={faPlusCircle} />
                                      </Button>
                                      :
                                      <></>}
                                  </Col>
                                  </Row>
                                  </div></Col>
                              </Row>
                                  :
                                  <></>}</><>
                                {children.indice == 4 ?
                                <Row>
                                <Col>
                                <div style={{ marginLeft: '90px' , fontSize:'14px'}}>{children.nombre}</div>
                                </Col>
                                <Col><div style={{ fontSize:'14px'}}>{children.descripcion}</div></Col>
                                <Col md="1"><div style={{ fontSize:'14px'}}>{children.precio_base}</div></Col>
                                <Col md="2"><div style={{ fontSize:'14px'}}>
                                <Row>
                                  <Col md="2">
                                    {!client.central ?
                                      <Button onClick={(event) => handleEditLocation(event, children)} type="submit" className="btn-transparent">
                                        <FontAwesomeIcon icon={faEdit} />
                                      </Button>
                                      :
                                      <></>}
                                  </Col>
                                  <Col md="2">
                                    {true ?
                                      <Button onClick={(event) => handleEraseLocation(event, children)} type="submit" className="btn-transparent">
                                        <FontAwesomeIcon icon={faTrash} />
                                      </Button>
                                      :
                                      <></>}
                                  </Col>

                                  <Col md="2">
                                    {!children.final ?
                                      <Button onClick={(event) => handleAddLocationChild(event, children)} type="submit" className="btn-transparent">
                                        <FontAwesomeIcon icon={faPlusCircle} />
                                      </Button>
                                      :
                                      <></>}
                                  </Col>
                                  </Row>
                                  </div></Col>
                              </Row>
                                  :
                                  <></>}</><>
                                {children.indice == 5 ?
                                <Row>
                                <Col>
                                <div style={{ marginLeft: '100px' , fontSize:'14px'}}>{children.nombre}</div>
                                </Col>
                                <Col><div style={{ fontSize:'14px'}}>{children.descripcion}</div></Col>
                                <Col md="1"><div style={{ fontSize:'14px'}}>{children.precio_base}</div></Col>
                                <Col md="2"><div style={{ fontSize:'14px'}}>
                                <Row>
                                  <Col md="2">
                                    {!client.central ?
                                      <Button onClick={(event) => handleEditLocation(event, children)} type="submit" className="btn-transparent">
                                        <FontAwesomeIcon icon={faEdit} />
                                      </Button>
                                      :
                                      <></>}
                                  </Col>
                                  <Col md="2">
                                    {true ?
                                      <Button onClick={(event) => handleEraseLocation(event, children)} type="submit" className="btn-transparent">
                                        <FontAwesomeIcon icon={faTrash} />
                                      </Button>
                                      :
                                      <></>}
                                  </Col>

                                  <Col md="2">
                                    {!children.final ?
                                      <Button onClick={(event) => handleAddLocationChild(event, children)} type="submit" className="btn-transparent">
                                        <FontAwesomeIcon icon={faPlusCircle} />
                                      </Button>
                                      :
                                      <></>}
                                  </Col>
                                  </Row>
                                  </div></Col>
                              </Row>
                                  :
                                  <></>}</></>
                          );

                        })}</>
                      :
                      <></>}

                  </></>

              )


            }
          
          }
            )}
              <Col  md={3}>
                <button className="btn-transparent-locations" style={{transform: 'translate(-15px,50%)', backgroundImage: `url(${iconAgregar})`, backgroundSize: '1.8em 1.8em', backgroundRepeat: 'no-repeat'    }} onClick={handleCreateMainCategorie}> </button>
              
                <button className="obex-btn btn-green btn btn-primary pl-3" style={{transform: 'translate(0.08em,50%)',}} onClick={handleCreateMainCategorie}>Agregar categoria</button>
              
              </Col>
            </>
            
          }

        </Col>
      </Row>
    </DashLayout>
  )
}

export default CategoryMaker;